<template>
  <div id="table-cash" class="table__container">
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="search__input w-90 px-2">
          <b-input-group>
            <b-form-input v-model="filter.search" placeholder="Cari nama kasir" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="pr-2">
          <b-button variant="outline-secondary py-1 bg-secondary text-white" @click="openCash()">
            <span>Buka Kas Kecil</span>
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="isLoading == true" class="d-flex justify-content-center mb-1 mt-5">
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table v-else max-height="80vh" :columns="columns" :rows="rows.data" :fixed-header="false" :sort-options="{
      enabled: false,
    }" :pagination-options="{
        enabled: false,
      }">
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'status'">
          <b-badge class="size12" :variant="props.row.status == 'opening' ? 'light-success' : 'light-danger'
            ">
            {{ props.row.status == "opening" ? "Buka" : "Tutup" }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'opening_cash'">
          <span>{{ props.row.opening_cash | formatAmount }}</span>
        </span>

        <!-- <span v-else-if="props.column.field === 'additional_cash'">
          <span v-if="props.row.additional_cash">{{ props.row.additional_cash | formatAmount }}</span>
          <span v-else>-</span>
        </span>
        
        <span v-else-if="props.column.field === 'cash_amount'">
          <span @click="goToDetail(props.row)">{{ props.row.cash_amount | formatAmount }}</span>
        </span>

        <span v-else-if="props.column.field === 'cash_out'">
          <span>{{ props.row.cash_out | formatAmount }}</span>
        </span> -->

        <!-- <span v-else-if="props.column.field === 'cash_change'">
          <span>{{ props.row.cash_change | formatAmount }}</span>
        </span> -->

        <span v-else-if="props.column.field === 'cash_summary'">
          <span>{{ formatToRupiah(props.row.cash_summary) }}</span>
        </span>

        <span v-else-if="props.column.field === 'action'" class="d-flex">
          <div class="text-center">
            <!-- <b-button 
              v-if="props.row.status == 'opening'" 
              class="px-4" variant="secondary" 
              @click="closeCash(props.row.uuid)"
              :disabled="props.row.operator_name !== user.name"
            >
              Tutup
            </b-button> -->
            <!-- <span class="border px-25 py-25 border-0 cursor-pointer" @click="onReload()">
              <feather-icon
                v-if="props.row.status == 'opening'"
                icon="RefreshCwIcon"
                size="2x"
              />
            </span> -->
            <!-- <b-button v-if="props.row.status == 'closing'" class="bg-white px-3" @click="getReport(props.row.uuid)">
              Laporan
            </b-button> -->
          </div>

          <b-dropdown v-if="
            props.row.status == 'opening' &&
            props.row.operator_name == user.name
          " id="dropdown-dropleft" class="d-flex" right>
            <template #button-content class="btn-white text-center">
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" />
            </template>
            <b-dropdown-item @click="closeCash(props.row.uuid)">
              <span> Lihat Detail </span>
            </b-dropdown-item>
            <b-dropdown-item @click="addCash(props.row.uuid)">
              <span> Tambahan Cash </span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown v-else-if="ownerRole === 'Owner' && props.row.status == 'opening'" id="dropdown-dropleft"
            class="d-flex" right>
            <template #button-content class="btn-white text-center">
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" />
            </template>
            <b-dropdown-item @click="closeCash(props.row.uuid)">
              <span> Lihat Detail </span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown v-else-if="props.row.status == 'closing'" id="dropdown-dropleft" class="d-flex" right>
            <template #button-content class="btn-white text-center">
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" />
            </template>
            <b-dropdown-item @click="getReport(props.row.uuid)">
              <span> Laporan </span>
            </b-dropdown-item>
          </b-dropdown>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <div v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border" style="padding: 0.8rem">
      <div class="d-flex align-items-center">
        <b-form-select v-model="filter.per_page" :options="['10', '15', '20']" class="mx-1" />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }}
          transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination class="mb-0" :data="rows" :limit="4" align="right" @pagination-change-page="getData" />
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalOpenCash :get-data="getData" :is-add-cash="isAddCash" :detail-result="detailResult" />

    <ModalCloseCash :get-data="getData" :detail-result="detailResult" />

    <ModalCashReport :get-data="getData" :detail-result="detailResult" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { errorNotification } from "@/auth/utils";
import {
  BPagination,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BBadge,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import ModalOpenCash from "@/components/Cash/ModalOpenCash.vue";
import ModalCloseCash from "@/components/Cash/ModalCloseCash.vue";
import ModalCashReport from "@/components/Cash/ModalCashReport.vue";

import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BBadge,
    BDropdown,
    BDropdownItem,
    ModalOpenCash,
    ModalCloseCash,
    ModalCashReport,
  },
  setup() {
    return {
      errorNotification,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      is_edit: false,
      isLoading: false,
      isAddCash: false,
      columns: [
        {
          label: "Tanggal",
          field: "opening_time",
        },
        {
          label: "Cash Awal",
          field: "opening_cash",
        },
        // {
        //   label: 'Cash Tambahan',
        //   field: 'additional_cash',
        // },
        // {
        //   label: 'Cash Masuk',
        //   field: 'cash_amount',
        // },
        // {
        //   label: 'Cash Keluar',
        //   field: 'cash_out',
        // },
        // {
        //   label: 'Jumlah Kembalian',
        //   field: 'cash_change',
        // },
        {
          label: "Total Cash",
          field: "cash_summary",
        },
        {
          label: "User",
          field: "operator_name",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: {},
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
      },
      detailResult: {},
      user: JSON.parse(localStorage.getItem("userData")),
      userRole: JSON.parse(localStorage.getItem("userRole")),
      ownerRole: null,
      cash_uuid: "",
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData();
    this.ownerRole = this.userRole.find((role) => role === "Owner");
  },
  methods: {
    formatToRupiah(amount) {
      const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$store
        .dispatch("pettyCash/getData", {
          params: queryParams,
        })
        .then((result) => {
          this.rows = result.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          this.isLoading = false;
          console.log(err);
        });
    },
    closeCash(id) {
      this.isAddCash = false;
      this.$store
        .dispatch("pettyCash/getDetail", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          this.detailResult = result.data.data;
          this.$bvModal.show("modal-close-cash");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getReport(id) {
      this.$store
        .dispatch("pettyCash/getDetail", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          this.detailResult = result.data.data;
          this.$bvModal.show("modal-cash-report");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openCash() {
      this.isAddCash = false;
      this.$bvModal.show("modal-open-cash");
    },
    addCash(id) {
      this.isAddCash = true;
      this.$store
        .dispatch("pettyCash/getDetail", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          this.detailResult = result.data.data;
          this.$bvModal.show("modal-open-cash");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onReload() {
      this.getData();
    },
    goToDetail(row) {
      const openingTime = row.opening_time;
      const date = new Date(openingTime);
      const formattedDate = date.toISOString().split("T")[0];
      this.$router.push({
        name: "transaction.index",
        query: { date: formattedDate },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

#table-cash {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;
  }
}
</style>
